exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-features-value-averaging-calculator-tsx": () => import("./../../../src/pages/features/value-averaging-calculator.tsx" /* webpackChunkName: "component---src-pages-features-value-averaging-calculator-tsx" */),
  "component---src-templates-archive-tsx": () => import("./../../../src/templates/archive.tsx" /* webpackChunkName: "component---src-templates-archive-tsx" */),
  "component---src-templates-blog-post-tsx": () => import("./../../../src/templates/blog-post.tsx" /* webpackChunkName: "component---src-templates-blog-post-tsx" */),
  "component---src-templates-index-tsx": () => import("./../../../src/templates/index.tsx" /* webpackChunkName: "component---src-templates-index-tsx" */),
  "component---src-templates-search-page-tsx": () => import("./../../../src/templates/search-page.tsx" /* webpackChunkName: "component---src-templates-search-page-tsx" */),
  "slice---src-components-archive-container-tsx": () => import("./../../../src/components/ArchiveContainer.tsx" /* webpackChunkName: "slice---src-components-archive-container-tsx" */),
  "slice---src-components-comment-tsx": () => import("./../../../src/components/Comment.tsx" /* webpackChunkName: "slice---src-components-comment-tsx" */),
  "slice---src-components-header-header-tsx": () => import("./../../../src/components/header/Header.tsx" /* webpackChunkName: "slice---src-components-header-header-tsx" */),
  "slice---src-components-pagination-element-tsx": () => import("./../../../src/components/PaginationElement.tsx" /* webpackChunkName: "slice---src-components-pagination-element-tsx" */),
  "slice---src-components-search-pagination-search-result-tsx": () => import("./../../../src/components/search/PaginationSearchResult.tsx" /* webpackChunkName: "slice---src-components-search-pagination-search-result-tsx" */),
  "slice---src-components-search-search-hits-result-tsx": () => import("./../../../src/components/search/SearchHitsResult.tsx" /* webpackChunkName: "slice---src-components-search-search-hits-result-tsx" */),
  "slice---src-components-search-voice-search-element-tsx": () => import("./../../../src/components/search/VoiceSearchElement.tsx" /* webpackChunkName: "slice---src-components-search-voice-search-element-tsx" */),
  "slice---src-components-sidebar-bio-tsx": () => import("./../../../src/components/sidebar/Bio.tsx" /* webpackChunkName: "slice---src-components-sidebar-bio-tsx" */),
  "slice---src-components-sidebar-right-sidebar-tsx": () => import("./../../../src/components/sidebar/RightSidebar.tsx" /* webpackChunkName: "slice---src-components-sidebar-right-sidebar-tsx" */),
  "slice---src-components-sidebar-tags-tsx": () => import("./../../../src/components/sidebar/Tags.tsx" /* webpackChunkName: "slice---src-components-sidebar-tags-tsx" */),
  "slice---src-components-socials-tsx": () => import("./../../../src/components/Socials.tsx" /* webpackChunkName: "slice---src-components-socials-tsx" */)
}

